import { lighten } from 'polished';
import { capitalize } from 'lodash-es';
import { ArticleOutlined, DeleteOutline, EditOutlined, MoreVert } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useAnchorMenu, useModal } from 'hooks';
import { useCallback, type FC, type MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EmbeddingType, type Embedding } from '../types';
import EmbeddingStatusChip from './EmbeddingStatusChip';
import { format } from 'date-fns';
import ConfirmEntityDeleteDialog from 'components/ConfirmEntityDeleteDialog';
import { useDeleteEmbedding } from '../api/deleteEmbedding';
import { useAuth } from 'lib/auth/AuthContext';
import handleDeletionError from '../../../utils/handleEntityDeletionError';
import useGetCategoryName from '../hooks/useGetCategoryName';

const TypeToText: Record<EmbeddingType, string> = {
  [EmbeddingType.Text]: 'Text',
  [EmbeddingType.Question]: 'Question',
};

interface EmbeddingListRowProps {
  index: number;
  selected: boolean;
  embedding: Embedding;
  highlightDefaultSort?: boolean;
  handleToggle: (id: string) => void;
}

const HighlightStrip = styled(Box)<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  background-color: ${({ theme, index }) => lighten(index * 0.15, theme.palette.primary.light)};
`;

const EmbeddingListRow: FC<EmbeddingListRowProps> = ({
  index,
  embedding,
  selected,
  highlightDefaultSort,
  handleToggle,
}) => {
  const { isViewer } = useAuth();

  const menu = useAnchorMenu();
  const deleteModal = useModal();
  const { indexPath } = useParams();
  const categoryName = useGetCategoryName(embedding.value.category);

  const hasAlternativeQuestions =
    embedding.value.type === EmbeddingType.Question &&
    (embedding.value.question2 || embedding.value.question3 || embedding.value.question4);

  const { mutate, isPending } = useDeleteEmbedding({
    indexPath: indexPath!,
    embeddingId: embedding.id,
    config: { onSuccess: () => deleteModal.close(), onError: handleDeletionError },
  });

  const handlePreventDefault = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const handleDelete = useCallback(() => {
    mutate();
  }, [mutate]);

  const handleDeleteMenuClick = useCallback(() => {
    deleteModal.open();
    menu.handleCloseMenu();
  }, [deleteModal, menu]);

  const lineStyle = {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    boxOrient: 'vertical',
    WebkitLineClamp: 3,
  };

  return (
    <>
      <ConfirmEntityDeleteDialog
        entityName="Embedding"
        entityId={embedding.id}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.close}
        isLoading={isPending}
        onDelete={handleDelete}
      />
      <TableRow
        hover
        component={Link}
        to={`${embedding.id}`}
        role="link"
        sx={{
          display: 'table-row',
          textDecoration: 'none',
        }}
      >
        <TableCell padding="checkbox" sx={{ position: 'relative', width: 80 }}>
          {highlightDefaultSort && <HighlightStrip index={index} />}
          <Checkbox
            onClick={(e) => {
              e.preventDefault();
              handleToggle(embedding.id);
            }}
            checked={selected}
            color="primary"
            inputProps={{
              'aria-labelledby': `table-checkbox-${embedding.id}`,
            }}
          />
        </TableCell>
        <TableCell>{embedding.id.substring(0, 5) + '...'}</TableCell>
        <TableCell>
          {embedding.value.type === EmbeddingType.Question && (
            <Box>
              <Typography mb={1}>
                <b>{embedding.value.question}</b>
                {hasAlternativeQuestions && <Chip label="Alternatives" size="small" sx={{ ml: 1 }} />}
              </Typography>
              <Typography sx={lineStyle}>{embedding.value.answer}</Typography>
            </Box>
          )}
          {embedding.value.type === EmbeddingType.Text && (
            <Typography sx={lineStyle}>{embedding.value.text}</Typography>
          )}
        </TableCell>
        <TableCell>{capitalize(categoryName)}</TableCell>
        <TableCell>{TypeToText[embedding.value.type]}</TableCell>
        <TableCell>
          <EmbeddingStatusChip status={embedding.status} />
        </TableCell>
        <TableCell>{format(embedding.createdAt, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{embedding.updatedAt && format(embedding.updatedAt, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell sx={{ textAlign: 'right' }} onClick={handlePreventDefault}>
          <IconButton onClick={menu.handleOpenMenu}>
            <MoreVert />
          </IconButton>

          <Menu
            anchorEl={menu.anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menu.isOpen}
            onClose={menu.handleCloseMenu}
          >
            <MenuItem component={Link} to={`${embedding.id}`} onClick={menu.handleCloseMenu}>
              <ListItemIcon>
                <ArticleOutlined />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
            <MenuItem component={Link} disabled={isViewer} to={`${embedding.id}/edit`} onClick={menu.handleCloseMenu}>
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem disabled={isViewer} onClick={handleDeleteMenuClick}>
              <ListItemIcon>
                <DeleteOutline color="error" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="error">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmbeddingListRow;
