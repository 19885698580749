import { DeleteOutline, Edit, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { SidePageLayout } from 'components';
import ConfirmEntityDeleteDialog from 'components/ConfirmEntityDeleteDialog';
import { useModal } from 'hooks';
import { useAuth } from 'lib/auth/AuthContext';
import { useCallback, type FC } from 'react';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import handleDeletionError from 'utils/handleEntityDeletionError';
import { useDeleteEmbedding, useGetEmbedding, useValidateEmbedding } from '../api';
import useGetCategoryName from '../hooks/useGetCategoryName';
import { EmbeddingStatus, EmbeddingType } from '../types';
import EmbeddingStatusChip from './EmbeddingStatusChip';
import { capitalize } from 'lodash-es';

interface ViewEmbeddingPageProps {
  embeddingId: string;
}

const ViewEmbeddingPage: FC<ViewEmbeddingPageProps> = ({ embeddingId }) => {
  const outletContext = useOutletContext<null | { listQuery: URLSearchParams }>();
  const listQuery = outletContext?.listQuery;

  const navigate = useNavigate();
  const deleteModal = useModal();
  const { indexPath } = useParams();
  const { data: embedding } = useGetEmbedding({ indexPath: indexPath!, embeddingId });
  const categoryName = useGetCategoryName(embedding.value.category);
  const { isViewer, isSupport } = useAuth();

  const { mutate: validate, isPending } = useValidateEmbedding({ indexPath: indexPath!, embeddingId });

  const handleValidate = () => {
    validate();
  };

  const { mutate: deleteEmbedding, isPending: loading } = useDeleteEmbedding({
    indexPath: indexPath!,
    embeddingId: embedding.id,
    config: {
      onSuccess: () => {
        deleteModal.close();
        navigate('..');
      },
      onError: handleDeletionError,
    },
  });

  const handleDelete = useCallback(() => {
    deleteEmbedding();
  }, [deleteEmbedding]);

  const footerActions = (
    <>
      <LoadingButton
        variant="outlined"
        color="success"
        loading={isPending}
        onClick={handleValidate}
        disabled={embedding.status === EmbeddingStatus.Verified || isViewer || isSupport}
      >
        Validate
      </LoadingButton>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="outlined"
          disabled={isViewer}
          color="primary"
          component={Link}
          to={`edit?redirect=/knowledge-bases/${indexPath}/${embedding.id}`}
          startIcon={<Edit />}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          disabled={isViewer}
          color="error"
          onClick={deleteModal.open}
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <ConfirmEntityDeleteDialog
        entityName="Embedding"
        entityId={embedding.id}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.close}
        isLoading={loading}
        onDelete={handleDelete}
      />
      <Box sx={{ flexGrow: 1 }}>
        <SidePageLayout
          fullHeight
          title={null}
          name="Close"
          footer={footerActions}
          backTo={{
            pathname: '..',
            search: listQuery ? listQuery.toString() : '',
          }}
        >
          <Stack spacing={2}>
            <Box>
              <EmbeddingStatusChip status={embedding.status} />
            </Box>
            <Box>
              <Typography variant="overline">Category</Typography>
              <Box>
                <Chip size="medium" label={capitalize(categoryName)} />
              </Box>
            </Box>
            {embedding.value.type === EmbeddingType.Question && (
              <>
                <Box>
                  <Typography variant="overline">Question</Typography>
                  <Paper sx={{ p: 2 }}>{embedding.value.question}</Paper>
                </Box>
                {embedding.value.question2 && (
                  <Box>
                    <Typography variant="overline">Question (2)</Typography>
                    <Paper sx={{ p: 2 }}>{embedding.value.question2}</Paper>
                  </Box>
                )}
                {embedding.value.question3 && (
                  <Box>
                    <Typography variant="overline">Question (3)</Typography>
                    <Paper sx={{ p: 2 }}>{embedding.value.question3}</Paper>
                  </Box>
                )}
                {embedding.value.question4 && (
                  <Box>
                    <Typography variant="overline">Question (4)</Typography>
                    <Paper sx={{ p: 2 }}>{embedding.value.question4}</Paper>
                  </Box>
                )}
                <Box>
                  <Typography variant="overline">Answer</Typography>
                  <Paper sx={{ p: 2, whiteSpace: 'pre-line' }}>{embedding.value.answer}</Paper>
                </Box>
              </>
            )}
            {embedding.value.type === EmbeddingType.Text && (
              <>
                <Box>
                  <Typography variant="overline">Text</Typography>
                  <Paper sx={{ p: 2 }}>{embedding.value.text}</Paper>
                </Box>
              </>
            )}
            <Box>
              <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0, background: 'transparent' }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
                  <Typography variant="overline">Meta</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Paper sx={{ px: 2, mt: 1 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">ID</TableCell>
                          <TableCell>{embedding.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Created by</TableCell>
                          <TableCell>{embedding.createdBy}</TableCell>
                        </TableRow>
                        {embedding.updatedBy && (
                          <TableRow>
                            <TableCell variant="head">Updated by</TableCell>
                            <TableCell>{embedding.updatedBy}</TableCell>
                          </TableRow>
                        )}
                        {embedding.validatedBy && (
                          <TableRow>
                            <TableCell variant="head">Validated by</TableCell>
                            <TableCell>{embedding.validatedBy}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </SidePageLayout>
      </Box>
    </>
  );
};
export default ViewEmbeddingPage;
