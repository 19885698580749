import type { QuestionEmbedding, TextEmbedding } from '../types';
import { EmbeddingType, type Embedding } from '../types';
import type { EmbeddingDTO, QuestionEmbeddingPayload, TextEmbeddingPayload } from './types';

const getQuestionEmbeddingFromDTO = (dto: QuestionEmbeddingPayload): QuestionEmbedding => ({
  type: EmbeddingType.Question,
  category: dto.category,
  question: dto.question,
  question2: dto.question2 ?? '',
  question3: dto.question3 ?? '',
  question4: dto.question4 ?? '',
  answer: dto.answer,
});

const getTextEmbeddingFromDTO = (dto: TextEmbeddingPayload): TextEmbedding => ({
  type: EmbeddingType.Text,
  category: dto.category,
  text: dto.text,
});

const transformEmbeddingValue = (dto: EmbeddingDTO['value']): Embedding['value'] => {
  let value: Embedding['value'];

  switch (dto.type) {
    case EmbeddingType.Text:
      value = getTextEmbeddingFromDTO(dto as TextEmbeddingPayload);
      break;
    case EmbeddingType.Question:
      value = getQuestionEmbeddingFromDTO(dto as QuestionEmbeddingPayload);
      break;
    default:
      throw new TypeError('Unknown Embedding type');
  }
  return value;
};

export default function getEmbeddingFromDTO(dto: EmbeddingDTO): Embedding {
  const embedding: Embedding = {
    ...dto,
    value: transformEmbeddingValue(dto.value),
    createdAt: new Date(dto.created_at),
    updatedAt: dto.updated_at ? new Date(dto.updated_at) : null,
    createdBy: dto.created_by,
    updatedBy: dto.updated_by,
    validatedBy: dto.validated_by,
  };

  return embedding;
}
