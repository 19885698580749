import type { QuestionEmbedding, TextEmbedding } from '../types';
import { EmbeddingType, type Embedding } from '../types';
import type { EmbeddingDTO, QuestionEmbeddingPayload, TextEmbeddingPayload } from './types';

const questionEmbeddingToDTO = (obj: QuestionEmbedding): QuestionEmbeddingPayload => ({
  type: EmbeddingType.Question,
  category: obj.category,
  question: obj.question,
  question2: obj.question2 ?? '',
  question3: obj.question3 ?? '',
  question4: obj.question4 ?? '',
  answer: obj.answer,
});

const textEmbeddingToDTO = (obj: TextEmbedding): TextEmbeddingPayload => ({
  type: EmbeddingType.Text,
  category: obj.category,
  text: obj.text,
});

const transformEmbeddingValue = (obj: Embedding['value']): EmbeddingDTO['value'] => {
  let value: EmbeddingDTO['value'];

  switch (obj.type) {
    case EmbeddingType.Text:
      value = textEmbeddingToDTO(obj as TextEmbedding);
      break;
    case EmbeddingType.Question:
      value = questionEmbeddingToDTO(obj as QuestionEmbedding);
      break;
    default:
      throw new TypeError('Unknown Embedding type');
  }
  return value;
};

export function embeddingToDTO(obj: Embedding): EmbeddingDTO {
  const embedding: EmbeddingDTO = {
    ...obj,
    value: transformEmbeddingValue(obj.value),
    created_at: obj.createdAt.toISOString(),
    updated_at: obj.updatedAt ? obj.updatedAt.toISOString() : null,
    created_by: obj.createdBy,
    updated_by: obj.updatedBy,
    validated_by: obj.validatedBy,
  };

  return embedding;
}
